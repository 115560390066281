// THIS FILE IS AUTO-GENERATED, DO NOT EDIT!
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  IntlTextField: any;
  JSON: any;
};

export type TAsset = {
  __typename?: "Asset";
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  fileExtension: Scalars["String"];
  fileKey: Scalars["String"];
  files?: Maybe<TAssetFiles>;
  frameRate?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isImage: Scalars["Boolean"];
  isVideo: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  originalFilename: Scalars["String"];
  processPending?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uploadPending: Scalars["Boolean"];
  /** This upload url is only returned once during creation and then it will be null. */
  uploadUrl?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
};

export enum TAssetFieldsEnum {
  Country = "COUNTRY",
  Date = "DATE",
  Description = "DESCRIPTION",
  Location = "LOCATION",
  Title = "TITLE",
}

export type TAssetFiles = {
  __typename?: "AssetFiles";
  large?: Maybe<Scalars["String"]>;
  medium?: Maybe<Scalars["String"]>;
  mp4Urls?: Maybe<Array<Maybe<Scalars["String"]>>>;
  small?: Maybe<Scalars["String"]>;
  thumbnails?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type TAssetInput = {
  mimeType?: InputMaybe<Scalars["String"]>;
  /** The original filename of the asset including its extension */
  originalFilename: Scalars["String"];
};

export type TCall = {
  __typename?: "Call";
  abbreviation: Scalars["String"];
  additionalRequirementsInfo?: Maybe<Scalars["String"]>;
  /** Whether the user can edit a submission */
  allowsRevocation: Scalars["Boolean"];
  callInfo?: Maybe<TCallInfo>;
  callPages: Array<TCallPage>;
  categories: Array<TSubmissionCategory>;
  contact?: Maybe<Scalars["String"]>;
  contestListTeaser?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  customFeeDescription?: Maybe<Scalars["String"]>;
  customFeeDescriptionShort?: Maybe<Scalars["String"]>;
  customNewsletterSignup1?: Maybe<Scalars["String"]>;
  customNewsletterSignup2?: Maybe<Scalars["String"]>;
  description: Scalars["IntlTextField"];
  fee: Scalars["Float"];
  forbidDuplicates: Scalars["Boolean"];
  /** List of profile fields that should be hidden from the user */
  hiddenProfileFields: Array<Maybe<TProfileFieldsEnum>>;
  id: Scalars["ID"];
  isFeeVoluntary?: Maybe<Scalars["Boolean"]>;
  logoDefault?: Maybe<Scalars["String"]>;
  maxProjects?: Maybe<Scalars["Int"]>;
  organisation?: Maybe<TOrganisation>;
  profileRequirements?: Maybe<Scalars["JSON"]>;
  requirements?: Maybe<Scalars["String"]>;
  slogan: Scalars["IntlTextField"];
  slug: Scalars["String"];
  submissionEndDate?: Maybe<Scalars["DateTime"]>;
  submissionIntroduction?: Maybe<Scalars["IntlTextField"]>;
  submissionStartDate?: Maybe<Scalars["DateTime"]>;
  termsAndConditions?: Maybe<Scalars["IntlTextField"]>;
  timeline?: Maybe<Scalars["JSON"]>;
  title?: Maybe<Scalars["IntlTextField"]>;
  transactionFee?: Maybe<Scalars["Float"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  useNewSubmissionWizard?: Maybe<Scalars["Boolean"]>;
  visibilityStatus: Scalars["String"];
  website?: Maybe<Scalars["String"]>;
};

export type TCallArchive = {
  __typename?: "CallArchive";
  calls: Array<Maybe<TCall>>;
  /** The number of items per page */
  limit: Scalars["Int"];
  /** The current page */
  page: Scalars["Int"];
  /** The total number of pages */
  pages: Scalars["Int"];
  /** The total number of items */
  total: Scalars["Int"];
};

export type TCallInfo = {
  __typename?: "CallInfo";
  call: TCall;
  costs?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  host?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  theme?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  whatToSubmit?: Maybe<Scalars["String"]>;
  whatToWin?: Maybe<Scalars["String"]>;
  whoCanSubmit?: Maybe<Scalars["String"]>;
};

export type TCallPage = {
  __typename?: "CallPage";
  about?: Maybe<Scalars["JSON"]>;
  brandColor?: Maybe<Scalars["String"]>;
  call: TCall;
  createdAt: Scalars["DateTime"];
  faq?: Maybe<Array<TCallPageFaq>>;
  hasFaq?: Maybe<Scalars["Boolean"]>;
  hasJury?: Maybe<Scalars["Boolean"]>;
  headerImage?: Maybe<Scalars["String"]>;
  headerImageCaption?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  jury?: Maybe<Array<TCallPageJury>>;
  jurySectionTitle?: Maybe<Scalars["String"]>;
  language: Scalars["String"];
  slug: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TCallPageFaq = {
  __typename?: "CallPageFaq";
  answer?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
};

export type TCallPageJurors = {
  __typename?: "CallPageJurors";
  avatar?: Maybe<Scalars["String"]>;
  bio?: Maybe<Scalars["String"]>;
  jobTitle?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TCallPageJury = {
  __typename?: "CallPageJury";
  jurors?: Maybe<Array<Maybe<TCallPageJurors>>>;
  title?: Maybe<Scalars["String"]>;
};

export enum TCallsFilterEntryStatus {
  Closed = "CLOSED",
  Open = "OPEN",
  OpenAndUpcoming = "OPEN_AND_UPCOMING",
  Upcoming = "UPCOMING",
}

export type TCallsFilterInput = {
  entryStatus?: InputMaybe<TCallsFilterEntryStatus>;
  visibilityStatus?: InputMaybe<TCallsFilterVisibilityStatus>;
};

export enum TCallsFilterVisibilityStatus {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export enum TCallsSort {
  Asc = "ASC",
  Desc = "DESC",
}

export type TDeleteConfirmation = {
  __typename?: "DeleteConfirmation";
  /** The id of the deleted resource */
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type TFinishSubmissionInput = {
  customNewsletterSignup1?: InputMaybe<Scalars["Boolean"]>;
  customNewsletterSignup2?: InputMaybe<Scalars["Boolean"]>;
};

export enum TGenderType {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER",
}

export type TInvitation = {
  __typename?: "Invitation";
  accepted: Scalars["Boolean"];
  call?: Maybe<TCall>;
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  expiresAt: Scalars["DateTime"];
  id: Scalars["ID"];
  issuer?: Maybe<TUser>;
  label?: Maybe<TJurorLabel>;
  message?: Maybe<Scalars["String"]>;
  /** The URL to redirect to when the invitation is accepted */
  redirectTo: Scalars["String"];
  scope: Scalars["String"];
  token: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TInvoice = {
  __typename?: "Invoice";
  createdAt: Scalars["DateTime"];
  currency: Scalars["String"];
  id: Scalars["ID"];
  items: Array<TInvoiceItem>;
  /** List of payments for this Invoice. Returns all the Stripe Checkouts that are in `paid` status. */
  payments: Array<TStripeCheckout>;
  remainingAmount: Scalars["Float"];
  total: Scalars["Float"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
  voucher?: Maybe<TVoucher>;
};

export type TInvoiceItem = {
  __typename?: "InvoiceItem";
  createdAt: Scalars["DateTime"];
  description: Scalars["String"];
  id: Scalars["ID"];
  price: Scalars["Float"];
  quantity: Scalars["Int"];
  title: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TJurorLabel = {
  __typename?: "JurorLabel";
  category?: Maybe<TSubmissionCategory>;
  id: Scalars["ID"];
};

export type TOrganisation = {
  __typename?: "Organisation";
  calls: Array<TCall>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["IntlTextField"]>;
  id: Scalars["ID"];
  name: Scalars["IntlTextField"];
  slug: Scalars["String"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TProfile = {
  __typename?: "Profile";
  artistName?: Maybe<Scalars["String"]>;
  biography?: Maybe<Scalars["String"]>;
  countryOrigin?: Maybe<Scalars["String"]>;
  countryResidence?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["Date"]>;
  facebook?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<TGenderType>;
  instagram?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  publicName?: Maybe<Scalars["String"]>;
  twitter?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  userId: Scalars["ID"];
  website?: Maybe<Scalars["String"]>;
};

export enum TProfileFieldsEnum {
  Address = "ADDRESS",
  ArtistName = "ARTIST_NAME",
  BioLong = "BIO_LONG",
  CompanyName = "COMPANY_NAME",
  CountryOrigin = "COUNTRY_ORIGIN",
  CountryResidence = "COUNTRY_RESIDENCE",
  Cv = "CV",
  DateOfBirth = "DATE_OF_BIRTH",
  Facebook = "FACEBOOK",
  Gender = "GENDER",
  Instagram = "INSTAGRAM",
  Phone = "PHONE",
  Profession = "PROFESSION",
  RepresentedByAgency = "REPRESENTED_BY_AGENCY",
  RepresentedByGallery = "REPRESENTED_BY_GALLERY",
  Twitter = "TWITTER",
  Website = "WEBSITE",
}

export type TProfileInput = {
  /** This field needs a better concept. It's likely to be deprecated in the future. */
  artistName?: InputMaybe<Scalars["String"]>;
  biography?: InputMaybe<Scalars["String"]>;
  countryOrigin?: InputMaybe<Scalars["String"]>;
  countryResidence?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["Date"]>;
  /** Facebook username */
  facebook?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  gender?: InputMaybe<TGenderType>;
  /** Instagram handle without leading @ */
  instagram?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  /** Twitter handle without leading @ */
  twitter?: InputMaybe<Scalars["String"]>;
  /** Url of the user website */
  website?: InputMaybe<Scalars["String"]>;
};

export type TProject = {
  __typename?: "Project";
  assets?: Maybe<Array<Maybe<TProjectAsset>>>;
  attributeOverrides?: Maybe<Scalars["JSON"]>;
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customSubmissionFields?: Maybe<Scalars["JSON"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  location: Scalars["String"];
  ownerId?: Maybe<Scalars["ID"]>;
  rootId?: Maybe<Scalars["ID"]>;
  submissionCategoryId?: Maybe<Scalars["ID"]>;
  submissionIndex?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  year: Scalars["String"];
};

export type TProjectAsset = {
  __typename?: "ProjectAsset";
  assetId: Scalars["ID"];
  country?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customFieldsValues?: Maybe<Scalars["JSON"]>;
  date?: Maybe<TProjectAssetDate>;
  description?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Int"]>;
  fileExtension: Scalars["String"];
  fileKey: Scalars["String"];
  files?: Maybe<TAssetFiles>;
  frameRate?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  isImage: Scalars["Boolean"];
  isVideo: Scalars["Boolean"];
  location?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  originalFilename: Scalars["String"];
  processPending?: Maybe<Scalars["Boolean"]>;
  submissionCategoryId: Scalars["ID"];
  title?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
  uploadPending: Scalars["Boolean"];
  width?: Maybe<Scalars["Int"]>;
};

export type TProjectAssetDate = {
  __typename?: "ProjectAssetDate";
  day?: Maybe<Scalars["Int"]>;
  month?: Maybe<Scalars["Int"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type TProjectAssetDateInput = {
  day?: InputMaybe<Scalars["Int"]>;
  month?: InputMaybe<Scalars["Int"]>;
  year?: InputMaybe<Scalars["Int"]>;
};

export type TProjectAssetInput = {
  /** The id of the asset that should be added to the project */
  assetId: Scalars["ID"];
  country?: InputMaybe<Scalars["String"]>;
  /** The creation date of the asset */
  date?: InputMaybe<TProjectAssetDateInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** The id of the project-asset. If not provided, a new project-asset will be created. */
  id?: InputMaybe<Scalars["ID"]>;
  location?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export enum TProjectFieldsEnum {
  Country = "COUNTRY",
  Description = "DESCRIPTION",
  Location = "LOCATION",
  Title = "TITLE",
  Year = "YEAR",
}

export type TProjectInput = {
  /** The assets of the project, will be stored in the provided order */
  assets: Array<TProjectAssetInput>;
  /** The country of the project */
  country?: InputMaybe<Scalars["String"]>;
  /** The custom fields of the project. Only available if the call has defined custom project fields. */
  customSubmissionFields?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  /** The location of the project */
  location?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
  /** The year of the project */
  year?: InputMaybe<Scalars["String"]>;
};

export type TRootMutationType = {
  __typename?: "RootMutationType";
  acceptInvitation?: Maybe<TInvitation>;
  applyVoucher?: Maybe<TInvoice>;
  createAsset?: Maybe<TAsset>;
  createSubmission?: Maybe<TSubmission>;
  deleteProject?: Maybe<TDeleteConfirmation>;
  finishSubmission?: Maybe<TSubmission>;
  updateNewsletterSubscription?: Maybe<TUser>;
  updateProfile?: Maybe<TProfile>;
  upsertProject?: Maybe<TProject>;
};

export type TRootMutationTypeAcceptInvitationArgs = {
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type TRootMutationTypeApplyVoucherArgs = {
  code?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["ID"];
};

export type TRootMutationTypeCreateAssetArgs = {
  input: TAssetInput;
};

export type TRootMutationTypeCreateSubmissionArgs = {
  callId: Scalars["ID"];
};

export type TRootMutationTypeDeleteProjectArgs = {
  id: Scalars["ID"];
};

export type TRootMutationTypeFinishSubmissionArgs = {
  id: Scalars["ID"];
  input?: InputMaybe<TFinishSubmissionInput>;
};

export type TRootMutationTypeUpdateNewsletterSubscriptionArgs = {
  active: Scalars["Boolean"];
  source?: InputMaybe<Scalars["String"]>;
};

export type TRootMutationTypeUpdateProfileArgs = {
  input: TProfileInput;
  submissionId: Scalars["ID"];
};

export type TRootMutationTypeUpsertProjectArgs = {
  categoryId: Scalars["ID"];
  id?: InputMaybe<Scalars["ID"]>;
  input: TProjectInput;
  submissionId: Scalars["ID"];
};

export type TRootQueryType = {
  __typename?: "RootQueryType";
  assets: Array<Maybe<TAsset>>;
  call?: Maybe<TCall>;
  callArchive: TCallArchive;
  callPage?: Maybe<TCallPage>;
  calls: Array<Maybe<TCall>>;
  invitation?: Maybe<TInvitation>;
  invoice?: Maybe<TInvoice>;
  myProfile?: Maybe<TProfile>;
  mySubmission?: Maybe<TSubmission>;
  mySubmissions: Array<TSubmission>;
  project?: Maybe<TProject>;
};

export type TRootQueryTypeAssetsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type TRootQueryTypeCallArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type TRootQueryTypeCallArchiveArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  page?: InputMaybe<Scalars["Int"]>;
};

export type TRootQueryTypeCallPageArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type TRootQueryTypeCallsArgs = {
  filter?: InputMaybe<TCallsFilterInput>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TCallsSort>;
};

export type TRootQueryTypeInvitationArgs = {
  id: Scalars["ID"];
  token: Scalars["String"];
};

export type TRootQueryTypeInvoiceArgs = {
  submissionId: Scalars["ID"];
};

export type TRootQueryTypeMyProfileArgs = {
  submissionId: Scalars["ID"];
};

export type TRootQueryTypeMySubmissionArgs = {
  callId: Scalars["ID"];
};

export type TRootQueryTypeMySubmissionsArgs = {
  callIds: Array<Scalars["ID"]>;
};

export type TRootQueryTypeProjectArgs = {
  id: Scalars["ID"];
};

/** Stripe Checkouts towards an Invoice. */
export type TStripeCheckout = {
  __typename?: "StripeCheckout";
  amount: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TSubmission = {
  __typename?: "Submission";
  call: TCall;
  /** Stripe checkout url. Only set in response to finishSubmission() mutation (if payment is required). */
  checkoutUrl?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  customNewsletterSignup1?: Maybe<Scalars["Boolean"]>;
  customNewsletterSignup2?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  invoice?: Maybe<TInvoice>;
  isCoordinated?: Maybe<Scalars["Boolean"]>;
  projects: Array<TProject>;
  status: TSubmissionStatus;
  submittedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type TSubmissionCategory = {
  __typename?: "SubmissionCategory";
  allowedFileTypes?: Maybe<Scalars["JSON"]>;
  call: TCall;
  createdAt: Scalars["DateTime"];
  customFields?: Maybe<Scalars["JSON"]>;
  description: Scalars["IntlTextField"];
  /** Contains the hidden fields for assets in this category. */
  hiddenAssetFields: Array<Maybe<TAssetFieldsEnum>>;
  /** Contains the hidden fields for projects in this category. */
  hiddenFields: Array<Maybe<TProjectFieldsEnum>>;
  id: Scalars["ID"];
  imageCustomFields?: Maybe<Scalars["JSON"]>;
  imagePrice?: Maybe<Scalars["Float"]>;
  imagePriceGraduation?: Maybe<Scalars["JSON"]>;
  label: Scalars["IntlTextField"];
  maxImages: Scalars["Int"];
  maxProjects: Scalars["Int"];
  maxVideoDuration?: Maybe<Scalars["Int"]>;
  minGifLongEdge?: Maybe<Scalars["Int"]>;
  minImageLongEdge: Scalars["Int"];
  minImages: Scalars["Int"];
  minProjects: Scalars["Int"];
  minVideoDuration?: Maybe<Scalars["Int"]>;
  minVideoLongEdge?: Maybe<Scalars["Int"]>;
  order?: Maybe<Scalars["Int"]>;
  price?: Maybe<Scalars["Float"]>;
  priceGraduation?: Maybe<Scalars["JSON"]>;
  /** Contains the required fields for assets in this category. */
  requiredAssetFields?: Maybe<Scalars["JSON"]>;
  /** Contains the required fields for projects in this category. */
  requiredFields?: Maybe<Scalars["JSON"]>;
  singleImagesCategory?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export enum TSubmissionStatus {
  Draft = "DRAFT",
  InEdit = "IN_EDIT",
  PaymentInProgress = "PAYMENT_IN_PROGRESS",
  Submitted = "SUBMITTED",
}

export type TUser = {
  __typename?: "User";
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<TGenderType>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  newsletter?: Maybe<Scalars["Boolean"]>;
  publicName?: Maybe<Scalars["String"]>;
};

export type TVoucher = {
  __typename?: "Voucher";
  code: Scalars["String"];
  type: TVoucherCalculationModeType;
  value: Scalars["Float"];
};

export enum TVoucherCalculationModeType {
  Fixed = "FIXED",
  Percentage = "PERCENTAGE",
}

export type TProjectFields = {
  __typename?: "Project";
  id: string;
  updatedAt?: any | null;
  title?: string | null;
  description?: string | null;
  location: string;
  country?: string | null;
  year: string;
  customSubmissionFields?: any | null;
  assets?: Array<{
    __typename?: "ProjectAsset";
    id: string;
    assetId: string;
    uploadPending: boolean;
    processPending?: boolean | null;
    title?: string | null;
    location?: string | null;
    country?: string | null;
    width?: number | null;
    height?: number | null;
    originalFilename: string;
    description?: string | null;
    isVideo: boolean;
    files?: {
      __typename?: "AssetFiles";
      medium?: string | null;
      mp4Urls?: Array<string | null> | null;
    } | null;
    date?: {
      __typename?: "ProjectAssetDate";
      year?: number | null;
      month?: number | null;
      day?: number | null;
    } | null;
  } | null> | null;
};

export type TLoadProjectVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TLoadProject = {
  __typename?: "RootQueryType";
  project?: {
    __typename?: "Project";
    id: string;
    updatedAt?: any | null;
    title?: string | null;
    description?: string | null;
    location: string;
    country?: string | null;
    year: string;
    customSubmissionFields?: any | null;
    assets?: Array<{
      __typename?: "ProjectAsset";
      id: string;
      assetId: string;
      uploadPending: boolean;
      processPending?: boolean | null;
      title?: string | null;
      location?: string | null;
      country?: string | null;
      width?: number | null;
      height?: number | null;
      originalFilename: string;
      description?: string | null;
      isVideo: boolean;
      files?: {
        __typename?: "AssetFiles";
        medium?: string | null;
        mp4Urls?: Array<string | null> | null;
      } | null;
      date?: {
        __typename?: "ProjectAssetDate";
        year?: number | null;
        month?: number | null;
        day?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TCreateAssetVariables = Exact<{
  input: TAssetInput;
}>;

export type TCreateAsset = {
  __typename?: "RootMutationType";
  createAsset?: {
    __typename?: "Asset";
    id: string;
    uploadUrl?: string | null;
    uploadPending: boolean;
    processPending?: boolean | null;
  } | null;
};

export type TAssetsVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type TAssets = {
  __typename?: "RootQueryType";
  assets: Array<{
    __typename?: "Asset";
    id: string;
    uploadPending: boolean;
    processPending?: boolean | null;
    width?: number | null;
    height?: number | null;
    isVideo: boolean;
    files?: {
      __typename?: "AssetFiles";
      medium?: string | null;
      mp4Urls?: Array<string | null> | null;
    } | null;
  } | null>;
};

export type TUpsertProjectVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]>;
  submissionId: Scalars["ID"];
  categoryId: Scalars["ID"];
  input: TProjectInput;
}>;

export type TUpsertProject = {
  __typename?: "RootMutationType";
  project?: {
    __typename?: "Project";
    id: string;
    updatedAt?: any | null;
    title?: string | null;
    description?: string | null;
    location: string;
    country?: string | null;
    year: string;
    customSubmissionFields?: any | null;
    assets?: Array<{
      __typename?: "ProjectAsset";
      id: string;
      assetId: string;
      uploadPending: boolean;
      processPending?: boolean | null;
      title?: string | null;
      location?: string | null;
      country?: string | null;
      width?: number | null;
      height?: number | null;
      originalFilename: string;
      description?: string | null;
      isVideo: boolean;
      files?: {
        __typename?: "AssetFiles";
        medium?: string | null;
        mp4Urls?: Array<string | null> | null;
      } | null;
      date?: {
        __typename?: "ProjectAssetDate";
        year?: number | null;
        month?: number | null;
        day?: number | null;
      } | null;
    } | null> | null;
  } | null;
};

export type TDeleteProjectVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TDeleteProject = {
  __typename?: "RootMutationType";
  deleteProject?: { __typename?: "DeleteConfirmation"; id: string } | null;
};

export type TFinishSubmissionVariables = Exact<{
  id: Scalars["ID"];
  input?: InputMaybe<TFinishSubmissionInput>;
}>;

export type TFinishSubmission = {
  __typename?: "RootMutationType";
  submission?: {
    __typename?: "Submission";
    id: string;
    status: TSubmissionStatus;
    checkoutUrl?: string | null;
  } | null;
};

export type TSubmissionWizardContentVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TSubmissionWizardContent = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    customNewsletterSignup1?: string | null;
    customNewsletterSignup2?: string | null;
    id: string;
    slug: string;
    logoDefault?: string | null;
    submissionEndDate?: any | null;
    title?: any | null;
    website?: string | null;
    allowsRevocation: boolean;
    categories: Array<{
      __typename?: "SubmissionCategory";
      id: string;
      label: any;
      description: any;
      singleImagesCategory?: boolean | null;
      minProjects: number;
      maxProjects: number;
      requiredFields?: any | null;
      requiredAssetFields?: any | null;
      hiddenFields: Array<TProjectFieldsEnum | null>;
      hiddenAssetFields: Array<TAssetFieldsEnum | null>;
      minImages: number;
      maxImages: number;
      minImageLongEdge: number;
      allowedFileTypes?: any | null;
      minVideoDuration?: number | null;
      maxVideoDuration?: number | null;
      minVideoLongEdge?: number | null;
      minGifLongEdge?: number | null;
      customFields?: any | null;
      imageCustomFields?: any | null;
    }>;
    organisation?: { __typename?: "Organisation"; name: any } | null;
  } | null;
};

export type TGetCallBySlugVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TGetCallBySlug = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    id: string;
    allowsRevocation: boolean;
    submissionEndDate?: any | null;
    submissionStartDate?: any | null;
    title?: any | null;
    logoDefault?: string | null;
    slug: string;
    callPages: Array<{ __typename?: "CallPage"; slug: string }>;
  } | null;
};

export type TCreateSubmissionVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type TCreateSubmission = {
  __typename?: "RootMutationType";
  submission?: {
    __typename?: "Submission";
    id: string;
    status: TSubmissionStatus;
  } | null;
};

export type TSubmissionWizardProfileVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TSubmissionWizardProfile = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    profileRequirements?: any | null;
    hiddenProfileFields: Array<TProfileFieldsEnum | null>;
    id: string;
    slug: string;
    logoDefault?: string | null;
    submissionEndDate?: any | null;
    title?: any | null;
    website?: string | null;
    allowsRevocation: boolean;
    organisation?: { __typename?: "Organisation"; name: any } | null;
  } | null;
};

export type TProfileFieldsToLoad = {
  __typename?: "Profile";
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: any | null;
  biography?: string | null;
  gender?: TGenderType | null;
  website?: string | null;
  countryOrigin?: string | null;
  countryResidence?: string | null;
  instagram?: string | null;
  twitter?: string | null;
  facebook?: string | null;
  updatedAt?: any | null;
};

export type TUpdateProfileVariables = Exact<{
  submissionId: Scalars["ID"];
  input: TProfileInput;
}>;

export type TUpdateProfile = {
  __typename?: "RootMutationType";
  user?: {
    __typename?: "Profile";
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: any | null;
    biography?: string | null;
    gender?: TGenderType | null;
    website?: string | null;
    countryOrigin?: string | null;
    countryResidence?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    facebook?: string | null;
    updatedAt?: any | null;
  } | null;
};

export type TLoadProfileVariables = Exact<{
  submissionId: Scalars["ID"];
}>;

export type TLoadProfile = {
  __typename?: "RootQueryType";
  myProfile?: {
    __typename?: "Profile";
    firstName?: string | null;
    lastName?: string | null;
    dateOfBirth?: any | null;
    biography?: string | null;
    gender?: TGenderType | null;
    website?: string | null;
    countryOrigin?: string | null;
    countryResidence?: string | null;
    instagram?: string | null;
    twitter?: string | null;
    facebook?: string | null;
    updatedAt?: any | null;
  } | null;
};

export type TSubmissionWizardSuccessVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TSubmissionWizardSuccess = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    id: string;
    slug: string;
    logoDefault?: string | null;
    submissionEndDate?: any | null;
    title?: any | null;
    website?: string | null;
    allowsRevocation: boolean;
    organisation?: { __typename?: "Organisation"; name: any } | null;
  } | null;
};

export type TSubmissionWizardSummaryVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TSubmissionWizardSummary = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    customNewsletterSignup1?: string | null;
    customNewsletterSignup2?: string | null;
    contact?: string | null;
    id: string;
    slug: string;
    logoDefault?: string | null;
    submissionEndDate?: any | null;
    title?: any | null;
    website?: string | null;
    allowsRevocation: boolean;
    categories: Array<{
      __typename?: "SubmissionCategory";
      minProjects: number;
      maxProjects: number;
      label: any;
    }>;
    organisation?: { __typename?: "Organisation"; name: any } | null;
  } | null;
};

export type TLoadInvoiceVariables = Exact<{
  submissionId: Scalars["ID"];
}>;

export type TLoadInvoice = {
  __typename?: "RootQueryType";
  invoice?: {
    __typename?: "Invoice";
    id: string;
    total: number;
    remainingAmount: number;
    currency: string;
    items: Array<{
      __typename?: "InvoiceItem";
      id: string;
      description: string;
      title: string;
      quantity: number;
      price: number;
    }>;
    payments: Array<{
      __typename?: "StripeCheckout";
      id: string;
      amount: number;
      createdAt: any;
    }>;
    voucher?: {
      __typename?: "Voucher";
      code: string;
      value: number;
      type: TVoucherCalculationModeType;
    } | null;
  } | null;
};

export type TApplyVoucherVariables = Exact<{
  invoiceId: Scalars["ID"];
  code?: InputMaybe<Scalars["String"]>;
}>;

export type TApplyVoucher = {
  __typename?: "RootMutationType";
  invoice?: {
    __typename?: "Invoice";
    total: number;
    voucher?: {
      __typename?: "Voucher";
      code: string;
      value: number;
      type: TVoucherCalculationModeType;
    } | null;
  } | null;
};

export type TUpdateNewsletterSubscriptionVariables = Exact<{
  active: Scalars["Boolean"];
  source?: InputMaybe<Scalars["String"]>;
}>;

export type TUpdateNewsletterSubscription = {
  __typename?: "RootMutationType";
  updateNewsletterSubscription?: {
    __typename?: "User";
    id: string;
    newsletter?: boolean | null;
  } | null;
};

export type TSubmissionWizardTermsVariables = Exact<{
  slug: Scalars["String"];
}>;

export type TSubmissionWizardTerms = {
  __typename?: "RootQueryType";
  call?: {
    __typename?: "Call";
    termsAndConditions?: any | null;
    id: string;
    slug: string;
    logoDefault?: string | null;
    submissionEndDate?: any | null;
    title?: any | null;
    website?: string | null;
    allowsRevocation: boolean;
    organisation?: { __typename?: "Organisation"; name: any } | null;
  } | null;
};

export type TWizardAppFields = {
  __typename?: "Call";
  id: string;
  slug: string;
  logoDefault?: string | null;
  submissionEndDate?: any | null;
  title?: any | null;
  website?: string | null;
  allowsRevocation: boolean;
  organisation?: { __typename?: "Organisation"; name: any } | null;
};

export type TMySubmissionVariables = Exact<{
  callId: Scalars["ID"];
}>;

export type TMySubmission = {
  __typename?: "RootQueryType";
  mySubmission?: {
    __typename?: "Submission";
    id: string;
    status: TSubmissionStatus;
    customNewsletterSignup1?: boolean | null;
    customNewsletterSignup2?: boolean | null;
    projects: Array<{
      __typename?: "Project";
      id: string;
      title?: string | null;
      description?: string | null;
      assets?: Array<{
        __typename?: "ProjectAsset";
        id: string;
        title?: string | null;
        files?: { __typename?: "AssetFiles"; small?: string | null } | null;
      } | null> | null;
    }>;
    invoice?: { __typename?: "Invoice"; id: string } | null;
  } | null;
};

export type TWizardCallsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TCallsFilterInput>;
}>;

export type TWizardCallsQuery = {
  __typename?: "RootQueryType";
  calls: Array<{
    __typename?: "Call";
    id: string;
    slug: string;
    title?: any | null;
    allowsRevocation: boolean;
    fee: number;
    transactionFee?: number | null;
    profileRequirements?: any | null;
    hiddenProfileFields: Array<TProfileFieldsEnum | null>;
    useNewSubmissionWizard?: boolean | null;
    categories: Array<{
      __typename?: "SubmissionCategory";
      price?: number | null;
      priceGraduation?: any | null;
      imagePrice?: number | null;
      imagePriceGraduation?: any | null;
      minProjects: number;
      maxProjects: number;
      hiddenFields: Array<TProjectFieldsEnum | null>;
      requiredFields?: any | null;
      hiddenAssetFields: Array<TAssetFieldsEnum | null>;
      requiredAssetFields?: any | null;
      singleImagesCategory?: boolean | null;
    }>;
  } | null>;
};

export type TCallPageHeroFields = {
  __typename?: "CallPage";
  headerImage?: string | null;
  headerImageCaption?: string | null;
  call: {
    __typename?: "Call";
    slug: string;
    useNewSubmissionWizard?: boolean | null;
    title?: any | null;
    logoDefault?: string | null;
    description: any;
    submissionStartDate?: any | null;
    submissionEndDate?: any | null;
    customFeeDescriptionShort?: string | null;
    isFeeVoluntary?: boolean | null;
    fee: number;
    currency: string;
    id: string;
    website?: string | null;
    categories: Array<{
      __typename?: "SubmissionCategory";
      price?: number | null;
      imagePrice?: number | null;
    }>;
    callInfo?: {
      __typename?: "CallInfo";
      id: string;
      costs?: string | null;
      host?: string | null;
      theme?: string | null;
      whatToSubmit?: string | null;
      whatToWin?: string | null;
      whoCanSubmit?: string | null;
    } | null;
  };
};

export type TQuickInfoFields = {
  __typename?: "Call";
  id: string;
  slug: string;
  submissionStartDate?: any | null;
  submissionEndDate?: any | null;
  title?: any | null;
  website?: string | null;
  useNewSubmissionWizard?: boolean | null;
  callInfo?: {
    __typename?: "CallInfo";
    id: string;
    costs?: string | null;
    host?: string | null;
    theme?: string | null;
    whatToSubmit?: string | null;
    whatToWin?: string | null;
    whoCanSubmit?: string | null;
  } | null;
};

export const ProjectFields = gql`
  fragment projectFields on Project {
    id
    updatedAt
    title
    description
    location
    country
    year
    assets {
      id
      assetId
      uploadPending
      processPending
      title
      location
      country
      width
      height
      originalFilename
      files {
        medium
        mp4Urls
      }
      description
      date {
        year
        month
        day
      }
      isVideo
    }
    customSubmissionFields
  }
`;
export const ProfileFieldsToLoad = gql`
  fragment profileFieldsToLoad on Profile {
    firstName
    lastName
    dateOfBirth
    biography
    gender
    website
    countryOrigin
    countryResidence
    instagram
    twitter
    facebook
    updatedAt
  }
`;
export const WizardAppFields = gql`
  fragment wizardAppFields on Call {
    id
    slug
    logoDefault
    submissionEndDate
    title
    organisation {
      name
    }
    website
    allowsRevocation
  }
`;
export const QuickInfoFields = gql`
  fragment quickInfoFields on Call {
    id
    slug
    submissionStartDate
    submissionEndDate
    title
    website
    useNewSubmissionWizard
    callInfo {
      id
      costs
      host
      theme
      whatToSubmit
      whatToWin
      whoCanSubmit
    }
  }
`;
export const CallPageHeroFields = gql`
  fragment callPageHeroFields on CallPage {
    headerImage
    headerImageCaption
    call {
      ...quickInfoFields
      slug
      useNewSubmissionWizard
      title
      logoDefault
      description
      submissionStartDate
      submissionEndDate
      customFeeDescriptionShort
      isFeeVoluntary
      fee
      currency
      categories {
        price
        imagePrice
      }
    }
  }
  ${QuickInfoFields}
`;
export const LoadProject = gql`
  query loadProject($id: ID!) {
    project: project(id: $id) {
      ...projectFields
    }
  }
  ${ProjectFields}
`;
export const CreateAsset = gql`
  mutation createAsset($input: AssetInput!) {
    createAsset(input: $input) {
      id
      uploadUrl
      uploadPending
      processPending
    }
  }
`;
export const Assets = gql`
  query assets($ids: [ID!]!) {
    assets(ids: $ids) {
      id
      uploadPending
      processPending
      width
      height
      isVideo
      files {
        medium
        mp4Urls
      }
    }
  }
`;
export const UpsertProject = gql`
  mutation upsertProject(
    $id: ID
    $submissionId: ID!
    $categoryId: ID!
    $input: ProjectInput!
  ) {
    project: upsertProject(
      id: $id
      submissionId: $submissionId
      categoryId: $categoryId
      input: $input
    ) {
      ...projectFields
    }
  }
  ${ProjectFields}
`;
export const DeleteProject = gql`
  mutation deleteProject($id: ID!) {
    deleteProject: deleteProject(id: $id) {
      id
    }
  }
`;
export const FinishSubmission = gql`
  mutation finishSubmission($id: ID!, $input: FinishSubmissionInput) {
    submission: finishSubmission(id: $id, input: $input) {
      id
      status
      checkoutUrl
    }
  }
`;
export const SubmissionWizardContent = gql`
  query submissionWizardContent($slug: String!) {
    call(slug: $slug) {
      ...wizardAppFields
      customNewsletterSignup1
      customNewsletterSignup2
      categories {
        id
        label
        description
        singleImagesCategory
        minProjects
        maxProjects
        requiredFields
        requiredAssetFields
        hiddenFields
        hiddenAssetFields
        minImages
        maxImages
        minImageLongEdge
        allowedFileTypes
        minVideoDuration
        maxVideoDuration
        minVideoLongEdge
        minGifLongEdge
        customFields
        imageCustomFields
      }
    }
  }
  ${WizardAppFields}
`;
export const GetCallBySlug = gql`
  query getCallBySlug($slug: String!) {
    call(slug: $slug) {
      id
      allowsRevocation
      submissionEndDate
      submissionStartDate
      title
      logoDefault
      slug
      callPages {
        slug
      }
    }
  }
`;
export const CreateSubmission = gql`
  mutation createSubmission($callId: ID!) {
    submission: createSubmission(callId: $callId) {
      id
      status
    }
  }
`;
export const SubmissionWizardProfile = gql`
  query submissionWizardProfile($slug: String!) {
    call(slug: $slug) {
      ...wizardAppFields
      profileRequirements
      hiddenProfileFields
    }
  }
  ${WizardAppFields}
`;
export const UpdateProfile = gql`
  mutation updateProfile($submissionId: ID!, $input: ProfileInput!) {
    user: updateProfile(submissionId: $submissionId, input: $input) {
      ...profileFieldsToLoad
    }
  }
  ${ProfileFieldsToLoad}
`;
export const LoadProfile = gql`
  query loadProfile($submissionId: ID!) {
    myProfile(submissionId: $submissionId) {
      ...profileFieldsToLoad
    }
  }
  ${ProfileFieldsToLoad}
`;
export const SubmissionWizardSuccess = gql`
  query submissionWizardSuccess($slug: String!) {
    call(slug: $slug) {
      ...wizardAppFields
    }
  }
  ${WizardAppFields}
`;
export const SubmissionWizardSummary = gql`
  query submissionWizardSummary($slug: String!) {
    call(slug: $slug) {
      ...wizardAppFields
      customNewsletterSignup1
      customNewsletterSignup2
      categories {
        minProjects
        maxProjects
        label
      }
      contact
    }
  }
  ${WizardAppFields}
`;
export const LoadInvoice = gql`
  query loadInvoice($submissionId: ID!) {
    invoice(submissionId: $submissionId) {
      id
      items {
        id
        description
        title
        quantity
        price
      }
      payments {
        id
        amount
        createdAt
      }
      total
      remainingAmount
      currency
      voucher {
        code
        value
        type
      }
    }
  }
`;
export const ApplyVoucher = gql`
  mutation applyVoucher($invoiceId: ID!, $code: String) {
    invoice: applyVoucher(invoiceId: $invoiceId, code: $code) {
      total
      voucher {
        code
        value
        type
      }
    }
  }
`;
export const UpdateNewsletterSubscription = gql`
  mutation updateNewsletterSubscription($active: Boolean!, $source: String) {
    updateNewsletterSubscription(active: $active, source: $source) {
      id
      newsletter
    }
  }
`;
export const SubmissionWizardTerms = gql`
  query submissionWizardTerms($slug: String!) {
    call(slug: $slug) {
      ...wizardAppFields
      termsAndConditions
    }
  }
  ${WizardAppFields}
`;
export const MySubmission = gql`
  query mySubmission($callId: ID!) {
    mySubmission(callId: $callId) {
      id
      status
      customNewsletterSignup1
      customNewsletterSignup2
      projects {
        id
        title
        description
        assets {
          id
          title
          files {
            small
          }
        }
      }
      invoice {
        id
      }
    }
  }
`;
export const WizardCallsQuery = gql`
  query wizardCallsQuery($limit: Int, $offset: Int, $filter: CallsFilterInput) {
    calls(limit: $limit, offset: $offset, filter: $filter) {
      id
      slug
      title
      allowsRevocation
      fee
      transactionFee
      categories {
        price
        priceGraduation
        imagePrice
        imagePriceGraduation
        minProjects
        maxProjects
        hiddenFields
        requiredFields
        hiddenAssetFields
        requiredAssetFields
        singleImagesCategory
      }
      profileRequirements
      hiddenProfileFields
      useNewSubmissionWizard
    }
  }
`;
